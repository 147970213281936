import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { FaTrashAlt, FaEdit, FaMicrophoneSlash, FaMicrophone, FaVolumeMute, FaVolumeUp } from 'react-icons/fa'; // Icons for navigation

import '../styles/chat.css';

// Web Crypto API requires the encryption key and initialization vector to be handled properly.
// We will now hash the ENCRYPTION_KEY to ensure it is 256 bits.

const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY || 'your-encryption-key'; // Secret key

function hexToUint8Array(hex) {
    if (hex.length % 2 !== 0) throw new Error('Invalid hex string');
    const arr = new Uint8Array(hex.length / 2);
    for (let i = 0; i < hex.length; i += 2) {
      arr[i / 2] = parseInt(hex.substring(i, i + 2), 16);
    }
    return arr;
  }
  

// Function to generate a 256-bit AES-GCM key from the ENCRYPTION_KEY string
async function generateKey() {
    const encoder = new TextEncoder();
    const keyMaterial = encoder.encode(ENCRYPTION_KEY);
  
    // Hash the key material using SHA-256 to create a 256-bit key (32 bytes)
    const hashedKey = await crypto.subtle.digest('SHA-256', keyMaterial);
  
    return crypto.subtle.importKey(
      'raw',
      hashedKey,
      { name: 'AES-GCM' },
      false,
      ['encrypt', 'decrypt']
    );
  }

async function encrypt(text) {
    const iv = crypto.getRandomValues(new Uint8Array(12)); // Generate a random 12-byte IV for AES-GCM
    const key = await generateKey();
    const encodedText = new TextEncoder().encode(text);
  
    // Encrypt the message using AES-GCM
    const encryptedBuffer = await crypto.subtle.encrypt(
      {
        name: 'AES-GCM',
        iv: iv,
      },
      key,
      encodedText
    );
  
    // Convert the encrypted buffer to a Uint8Array
    const encryptedArray = new Uint8Array(encryptedBuffer);
  
    // Split the encrypted data: ciphertext and authTag (last 16 bytes)
    const ciphertext = encryptedArray.slice(0, encryptedArray.length - 16);
    const authTag = encryptedArray.slice(encryptedArray.length - 16);
  
    return {
      iv: Array.from(iv), // IV in array form
      encryptedData: Array.from(ciphertext), // Ciphertext in array form
      authTag: Array.from(authTag), // AuthTag in array form (last 16 bytes)
    };
  }
  

  async function decrypt(encryptedMessage) {
    let encryptedMessageJSON;
    try {
        encryptedMessageJSON = JSON.parse(encryptedMessage);
    } catch(err) {
        encryptedMessageJSON = encryptedMessage;
    }
    // Check if iv, encryptedData, and authTag are present
    if (!encryptedMessageJSON.iv || !encryptedMessageJSON.encryptedData || !encryptedMessageJSON.authTag) {
        throw new Error("Decryption failed: missing parameters");
    }
  
    // Convert hex strings to Uint8Array using the helper function
    const iv = hexToUint8Array(encryptedMessageJSON.iv);  // Convert IV from hex to Uint8Array
    const encryptedText = hexToUint8Array(encryptedMessageJSON.encryptedData);  // Convert encrypted text from hex to Uint8Array
    const authTag = hexToUint8Array(encryptedMessageJSON.authTag);  // Convert authTag from hex to Uint8Array
  
    // Combine the encrypted text and authTag (Web Crypto API doesn't expose authTag directly)
    const combinedText = new Uint8Array([...encryptedText, ...authTag]);
  
    const key = await generateKey();  // Get the AES-GCM key
  
    // Perform decryption using AES-GCM
    const decryptedBuffer = await crypto.subtle.decrypt(
      {
        name: 'AES-GCM',
        iv: iv,  // The IV used during encryption
      },
      key,
      combinedText  // Combined encrypted text and authTag
    );
  
    return new TextDecoder().decode(decryptedBuffer);  // Decode the decrypted buffer to a string
  }
  

  function Chat() {
    const [sessions, setSessions] = useState([]); // List of chat sessions
    const [currentSession, setCurrentSession] = useState(null); // Active session
    const [currentSessionTopic, setCurrentSessionTopic] = useState(null); // Active session
    const [messages, setMessages] = useState([]); // Messages in current session
    const [input, setInput] = useState(''); // User input
    const [sentiments, setSentiments] = useState([]); // Sentiment history
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false); // Sidebar collapse state
    const [editingSession, setEditingSession] = useState(null); // Track the session being edited
    const [loading, setLoading] = useState(false); // Loading state
    const [recognizing, setRecognizing] = useState(false); // Voice recognition state
    const [voices, setVoices] = useState([]); // Available voices
    const [selectedVoice, setSelectedVoice] = useState(null); // Selected voice for speech synthesis
    const [isMuted, setIsMuted] = useState(false); // Mute state for TTS

    let recognition;
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    
    if (SpeechRecognition) {
        recognition = new SpeechRecognition();
        recognition.continuous = true; // Enable continuous recognition
        recognition.interimResults = true; // Provide interim results
        recognition.lang = 'en-US'; // Language preference
    }

    // Load available voices for speech synthesis
    useEffect(() => {
        const loadVoices = () => {
            const availableVoices = window.speechSynthesis.getVoices();
            setVoices(availableVoices);
            if (availableVoices.length > 0) {
                setSelectedVoice(availableVoices[0]); // Set default voice
            }
        };

        if (window.speechSynthesis.onvoiceschanged !== undefined) {
            window.speechSynthesis.onvoiceschanged = loadVoices;
        } else {
            loadVoices();
        }
    }, []);

    // Start voice recognition
    const startVoiceRecognition = () => {
        if (recognition) {
            recognition.start();
            setRecognizing(true);
        }
    };

    // Stop voice recognition
    const stopVoiceRecognition = () => {
        if (recognition) {
            recognition.stop();
            setRecognizing(false);
        }
    };


    // Toggle voice recognition with button
    const toggleVoiceRecognition = () => {
        if (recognizing) {
            stopVoiceRecognition();
        } else {
            startVoiceRecognition();
        }
    };

    // Fetch chat sessions when component loads
    useEffect(() => {
        const fetchSessions = async () => {
            const token = localStorage.getItem('token');
            const response = await fetch(`${process.env.REACT_APP_API_URL}/chat/sessions`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            const data = await response.json();
            setSessions(data); // Set chat sessions
        };
        fetchSessions();
    }, []);


    // Handle recognition result
    useEffect(() => {
        if (recognition) {
            recognition.onresult = (event) => {
                let transcript = event.results[event.resultIndex][0].transcript;
                transcript = transcript.trim();
                if (event.results[event.resultIndex].isFinal) {
                    setInput((prevInput) => `${prevInput} ${transcript}`);
                }
            };

            recognition.onerror = (event) => {
                console.error("Speech recognition error", event.error);
                setRecognizing(false);
            };
        }
    }, [recognition]);

    // Toggle mute state for TTS
    const toggleMute = () => {
        setIsMuted(!isMuted);
    };

    // Fetch messages and sentiment history for the selected session
    const fetchMessagesAndSentiments = async (sessionId, topic) => {
        const token = localStorage.getItem('token');
        setCurrentSession(sessionId);
        setCurrentSessionTopic(topic);

        const messagesResponse = await fetch(`${process.env.REACT_APP_API_URL}/chat/${sessionId}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        const messagesData = await messagesResponse.json();
        const decryptedMessages = await Promise.all(
            messagesData.map(async (message) => {
                const decryptedUserMessage = await decrypt(message.user.text);
                const decryptedBotMessage = await decrypt(message.bot.text);
                const decryptedSentiment = await decrypt(message.sentiment.text);
                return { user: decryptedUserMessage, bot: decryptedBotMessage, sentiment: decryptedSentiment, timestamp: message.timestamp };
            })
        );
        setMessages(decryptedMessages);
        setCurrentSession(sessionId);
        setCurrentSessionTopic(topic);

        // Fetch sentiment history
        const sentimentsResponse = await fetch(`${process.env.REACT_APP_API_URL}/chat/${sessionId}/sentiments`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        const sentimentsData = await sentimentsResponse.json();
        const decryptedSentiments = await Promise.all(
            sentimentsData.map(async (message) => {
                const decryptedSentiment = await decrypt(message.sentiment.text);
                return { sentiment: decryptedSentiment, timestamp: message.timestamp };
            })
        );
        setSentiments(decryptedSentiments); // Set sentiment history
    };



    // Handle recognition result
    useEffect(() => {
        if (recognition) {
            recognition.onresult = (event) => {
                let transcript = event.results[event.resultIndex][0].transcript;
                transcript = transcript.trim();
                if (event.results[event.resultIndex].isFinal) {
                    setInput((prevInput) => `${prevInput} ${transcript}`); // Append text
                }
            };

            recognition.onerror = (event) => {
                console.error("Speech recognition error", event.error);
                setRecognizing(false);
            };
        }
    }, [recognition]);


    // Text-to-Speech for assistant's response
    const speakOutLoud = (text) => {
        if (isMuted) return; // Do nothing if muted

        const utterance = new SpeechSynthesisUtterance(text);
        utterance.lang = 'en-US';
        if (selectedVoice) {
            utterance.voice = selectedVoice;
        }
        window.speechSynthesis.speak(utterance);
    };


    // Send a message in the current session
    const sendMessage = async () => {
        stopVoiceRecognition();
        if (!currentSession) return; // Ensure a session is selected
        setLoading(true); // Set loading to true when processing starts

        const token = localStorage.getItem('token');
        const encryptedMessage = await encrypt(input);

        const response = await fetch(`${process.env.REACT_APP_API_URL}/chat/${currentSession}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ message: encryptedMessage }),
        });

        const data = await response.json();
        const decryptedBotMessage = await decrypt(data.response);
        const decryptedSentiment = await decrypt(data.sentiment);
        const decryptedSessionName = await decrypt(data.session_name);
        setCurrentSessionTopic(decryptedSessionName);
        setMessages([...messages, { user: input, bot: decryptedBotMessage, sentiment: decryptedSentiment }]);
        setInput('');


        // Speak out the bot's message
        speakOutLoud(decryptedBotMessage);

        // Fetch sentiment history
        const sentimentsResponse = await fetch(`${process.env.REACT_APP_API_URL}/chat/${currentSession}/sentiments`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        const sentimentsData = await sentimentsResponse.json();
        const decryptedSentiments = await Promise.all(
            sentimentsData.map(async (message) => {
                const decryptedSentiment = await decrypt(message.sentiment.text);
                return { sentiment: decryptedSentiment, timestamp: message.timestamp };
            })
        );
        setSentiments(decryptedSentiments); // Set sentiment history
        setLoading(false); // Set loading to false when processing is done
    };

    // Create a new chat session
    const createNewSession = async () => {
        const token = localStorage.getItem('token');
        const response = await fetch(`${process.env.REACT_APP_API_URL}/chat/create-session`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        });

        const data = await response.json();
        setSessions([...sessions, data]); // Add new session to the list
        fetchMessagesAndSentiments(data._id); // Automatically switch to the new session
    };

    // Toggle the sidebar visibility
    const toggleSidebar = () => {
        setSidebarCollapsed(!sidebarCollapsed);
    };

    // Rename a session
    const renameSession = async (sessionId, newName) => {
        const token = localStorage.getItem('token');
        const response = await fetch(`${process.env.REACT_APP_API_URL}/chat/${sessionId}/rename`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ name: newName }),
        });

        if (response.ok) {
            setSessions(sessions.map((session) => 
                session._id === sessionId ? { ...session, topic: newName } : session
            ));
            setEditingSession(null); // Stop editing mode after rename
        }
    };

    // Delete a session
    const deleteSession = async (sessionId) => {
        const token = localStorage.getItem('token');
        const response = await fetch(`${process.env.REACT_APP_API_URL}/chat/${sessionId}`, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });

        if (response.ok) {
            setSessions(sessions.filter(session => session._id !== sessionId));
            if (currentSession === sessionId) {
                setCurrentSession(null); // If the current session is deleted, deselect it
                setMessages([]); // Clear messages if current session was deleted
            }
        }
    };

    return (
        <div className="chat-app-container">
            {/* Sidebar toggle button */}
            <button onClick={toggleSidebar} className="toggle-sidebar-button" style={sidebarCollapsed ? {'background': 'rgba(240,240,240)'}: {}}>
                {sidebarCollapsed ? '☰' : '☰'}
            </button>

            {/* Sidebar for chat sessions */}
            {/* Sidebar for chat sessions, hidden when collapsed */}
            {!sidebarCollapsed && (
                    <aside className="chat-sidebar">
                    <h2>Conversations</h2>
                    <button onClick={createNewSession} className="new-chat-button">+ New Chat</button>
                    <ul>
                        {sessions.map((session) => (
                            
                            <li
                                key={session._id}
                                onClick={() => fetchMessagesAndSentiments(session._id, session.topic)}
                                className={session._id === currentSession ? 'active' : ''}
                            >
                                <br></br>
                                {/* Editable session name */}
                                {editingSession === session._id ? (
                                    <input
                                        type="text"
                                        defaultValue={session.topic}
                                        onBlur={(e) => renameSession(session._id, e.target.value)}
                                    />
                                ) : (
                                    <span onClick={() => fetchMessagesAndSentiments(session._id)}>
                                        {session._id === currentSession ? currentSessionTopic: session.topic || 'Untitled Chat'}
                                    </span>
                                )}
                                <br></br>
                                {/* Edit and Delete buttons */}
                                <button onClick={() => setEditingSession(session._id)} style={{'fontSize': 'small', padding: '0px', margin: '0px', alignSelf:'right',}}><FaEdit className="nav-icon" style={{'fontSize': 'small', padding: '0px', margin: '0px', alignItems:'right',}}/></button>
                                <button onClick={() => deleteSession(session._id)} style={{'fontSize': 'small', padding: '0px', margin: '0px', alignSelf:'right',}}><FaTrashAlt className="nav-icon" style={{'fontSize': 'small', padding: '0px', margin: '0px', alignItems:'right',}} /></button>
                                
                            </li>
                        ))}
                    </ul>
                </aside>
            )}

            {/* Chat interface */}
            <div className="chat-container">
                <p color='Red'>This is the development version. Please do not enter confidential Information!</p>
                <h2>{currentSession ? 'Chat with Your Assistant' : 'Select a Chat or Start a New One'}</h2>
                <div className="chat-messages">
                    {messages.map((msg, index) => (
                        <div key={index}>
                            <p><strong>You:</strong> {msg.user}</p>
                            <div className="assistant-message">
                                <ReactMarkdown className="assistant-content">
                                    {typeof msg.bot === 'string' ? msg.bot : JSON.stringify(msg.bot)}
                                </ReactMarkdown>
                            </div>
                        </div>
                    ))}
                    {/* Mute/Read Out Loud Button */}
                    <button onClick={toggleMute} className="mute-button">
                        {isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
                    </button>
                </div>

                {currentSession && (
                    <div className="chat-input">
                        <input
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            placeholder="Type your message"
                        />
                        <button onClick={sendMessage} disabled={loading}>
                            {loading ? 'Processing...' : 'Send'}
                        </button>
                        {/* Voice input button */}
                        <button onClick={toggleVoiceRecognition}>
                            {recognizing ? <FaMicrophoneSlash /> : <FaMicrophone />}
                        </button>
                    </div>
                )}


                {/* Voice Selection */}
                <div className="voice-selection">
                    <label htmlFor="voices">Select Assistant's Voice: </label>
                    <select
                        id="voices"
                        onChange={(e) => setSelectedVoice(voices.find((voice) => voice.name === e.target.value))}
                    >
                        {voices.map((voice, index) => (
                            <option key={index} value={voice.name}>
                                {voice.name} ({voice.lang})
                            </option>
                        ))}
                    </select>
                </div>

                {/* Sentiment History */}
                <div className="sentiment-history">
                    <h3>Sentiment History</h3>
                    <ul>
                        {sentiments.map((entry, index) => (
                            <li key={index}>
                                <p><strong>Sentiment:</strong> {entry.sentiment}</p>
                                <p><strong>Timestamp:</strong> {new Date(entry.timestamp).toLocaleString()}</p>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Chat;
