import React, { useRef, useEffect, useState, useCallback } from 'react';
import * as faceapi from 'face-api.js';

function FaceLandmarksDetection() {
  const videoRef = useRef(null);
  const [selectedDeviceId, setSelectedDeviceId] = useState(null);
  const [devices, setDevices] = useState([]);
  const [emotion, setEmotion] = useState('');

  useEffect(() => {
    const loadFaceApiModels = async () => {
      await faceapi.nets.tinyFaceDetector.loadFromUri('/models');
      await faceapi.nets.faceExpressionNet.loadFromUri('/models');
      console.log('Models Loaded');
    };

    loadFaceApiModels();
    getDevices();
  }, []);

  const getDevices = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const videoDevices = devices.filter(device => device.kind === 'videoinput');
    setDevices(videoDevices);
    if (videoDevices.length > 0) {
      setSelectedDeviceId(videoDevices[0].deviceId);
    }
  };

  const startVideoStream = useCallback((deviceId) => {
    navigator.mediaDevices
      .getUserMedia({ video: { deviceId: { exact: deviceId } } })
      .then((stream) => {
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          videoRef.current.play();
          detectEmotions();
        }
      })
      .catch((err) => console.error('Error accessing webcam:', err));
  }, []);

  useEffect(() => {
    if (selectedDeviceId) {
      const currentVideoRef = videoRef.current; // Local copy of videoRef for cleanup
      startVideoStream(selectedDeviceId);
      
      return () => {
        if (currentVideoRef) {
          const stream = currentVideoRef.srcObject;
          if (stream) {
            stream.getTracks().forEach(track => track.stop());
          }
        }
      };
    }
  }, [selectedDeviceId, startVideoStream]);

  const detectEmotions = async () => {
    const video = videoRef.current;

    if (!faceapi.nets.tinyFaceDetector.isLoaded || !faceapi.nets.faceExpressionNet.isLoaded) {
      console.error('Models are not loaded yet');
      return;
    }

    const detectionLoop = async () => {
      if (video.readyState === 4) {
        const options = new faceapi.TinyFaceDetectorOptions();
        const detections = await faceapi.detectAllFaces(video, options).withFaceExpressions();
        if (detections.length > 0) {
          const maxExpression = Object.keys(detections[0].expressions).reduce((a, b) =>
            detections[0].expressions[a] > detections[0].expressions[b] ? a : b
          );
          setEmotion(maxExpression);
        }
      }

      requestAnimationFrame(detectionLoop);
    };

    detectionLoop();
  };

  return (
    <div className="chat-container">
      <h2>Facial Landmark Detection with Emotion Recognition</h2>
      <div>
        <label htmlFor="webcamSelect">Select Webcam: </label>
        <select
          id="webcamSelect"
          onChange={(e) => setSelectedDeviceId(e.target.value)}
        >
          {devices.map((device, index) => (
            <option key={index} value={device.deviceId}>
              {device.label || `Camera ${index + 1}`}
            </option>
          ))}
        </select>
      </div>
      <h3>Detected Emotion: {emotion}</h3>
      <div style={{ position: 'relative', width: '640px', height: '480px' }}>
        <video
          ref={videoRef}
          autoPlay
          muted
          playsInline
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '640px',
            height: '480px',
          }}
        ></video>
      </div>
    </div>
  );
}

export default FaceLandmarksDetection;
