import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Register from './components/Register';
import Login from './components/Login';
import Logout from './components/Logout';
import Onboarding from './components/Onboarding';
import Chat from './components/Chat';
import PostAuthHandler from './components/PostAuthHandler';

import Layout from './components/Layout';
import HomePage from './components/HomePage';
import EmotionDetection from './components/EmotionDetection';
import FaceLandmarksDetection from './components/FacialLandmarksDetection';

import './App.css';


const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem('token');
  console.log('I will start running!');
  return token ? children : <Navigate to="/login" />;
};


function App() {
  return (
    <Router>
      <Routes>
      <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="register" element={<Register />} />
          <Route path="login" element={<Login />} />
          <Route path="logout" element={<Logout />} />
          <Route path="post-auth" element={<PostAuthHandler />} />
          <Route path="chat" element={
            <PrivateRoute>
              < Chat/>
            </PrivateRoute>
          } />
          <Route path="emotions" element={
            <PrivateRoute>
              < EmotionDetection/>
            </PrivateRoute>
          } />
          <Route path="face" element={
            <PrivateRoute>
              < FaceLandmarksDetection/>
            </PrivateRoute>
          } />
          <Route path="onboarding" element={
            <PrivateRoute>
              < Onboarding/>
            </PrivateRoute>
          } />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
