import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch(`${process.env.REACT_APP_API_URL}/users/login`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, password }),
      credentials: 'include', // Ensure cookies are sent with the request
    });
    const data = await response.json();
    if (data.error) {
      alert(data.error);
    } else {
      localStorage.setItem('token', data.token);
      localStorage.setItem('userId', data.userId);
      localStorage.setItem('email', email);
      console.log(data);
      console.log(data.token);
      console.log(data.userId);
      console.log(data.email);
      navigate('/onboarding'); // This replaces history.push
    }
  };

  const handleOAuth = (provider) => {
    // Store desired return path (e.g., onboarding) in sessionStorage
    sessionStorage.setItem('postAuthRedirect', '/onboarding');
    
    window.location.href = `${process.env.REACT_APP_API_URL}/users/${provider}`;
    navigate('/onboarding'); // This replaces history.push
  };

  return (
    <div style={{ width: '60vw', padding: 'auto', margin: '150px' }}>
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <button type="submit" style={{ backgroundColor: '#4CAF50' }}>Login</button>
      </form>
      <div style={{ marginTop: '20px' }}>
        <h4>Or login with:</h4>
        <button onClick={() => handleOAuth('google')} style={buttonStyle}>
          <img src="/google-logo.png" alt="Google" style={logoStyle} />
          <p style={{ color: 'black'}}>Login with Google</p>
        </button>
        <button onClick={() => handleOAuth('microsoft')} style={buttonStyle}>
          <img src="/microsoft-logo.png" alt="Microsoft" style={logoStyle} />
          <p>Login with Microsoft</p>
        </button>
      </div>
    </div>
  );
}

const buttonStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '10px 0',
  padding: '10px 20px',
  border: '1px solid #ccc',
  borderRadius: '5px',
  cursor: 'pointer',
  background: '#fff',
};

const logoStyle = {
  width: '20px',
  height: '20px',
  marginRight: '10px',
};

export default Login;
