import React, { useState, useEffect, useCallback } from 'react';
import ReactMarkdown from 'react-markdown';
import { useNavigate } from 'react-router-dom';

import '../styles/chat.css';

// Web Crypto API requires the encryption key and initialization vector to be handled properly.
// We will now hash the ENCRYPTION_KEY to ensure it is 256 bits.

const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY || 'your-encryption-key'; // Secret key

function hexToUint8Array(hex) {
    if (hex.length % 2 !== 0) throw new Error('Invalid hex string');
    const arr = new Uint8Array(hex.length / 2);
    for (let i = 0; i < hex.length; i += 2) {
      arr[i / 2] = parseInt(hex.substring(i, i + 2), 16);
    }
    return arr;
  }
  

// Function to generate a 256-bit AES-GCM key from the ENCRYPTION_KEY string
async function generateKey() {
    const encoder = new TextEncoder();
    const keyMaterial = encoder.encode(ENCRYPTION_KEY);
  
    // Hash the key material using SHA-256 to create a 256-bit key (32 bytes)
    const hashedKey = await crypto.subtle.digest('SHA-256', keyMaterial);
  
    return crypto.subtle.importKey(
      'raw',
      hashedKey,
      { name: 'AES-GCM' },
      false,
      ['encrypt', 'decrypt']
    );
  }

async function encrypt(text) {
    const iv = crypto.getRandomValues(new Uint8Array(12)); // Generate a random 12-byte IV for AES-GCM
    const key = await generateKey();
    const encodedText = new TextEncoder().encode(text);
  
    // Encrypt the message using AES-GCM
    const encryptedBuffer = await crypto.subtle.encrypt(
      {
        name: 'AES-GCM',
        iv: iv,
      },
      key,
      encodedText
    );
  
    // Convert the encrypted buffer to a Uint8Array
    const encryptedArray = new Uint8Array(encryptedBuffer);
  
    // Split the encrypted data: ciphertext and authTag (last 16 bytes)
    const ciphertext = encryptedArray.slice(0, encryptedArray.length - 16);
    const authTag = encryptedArray.slice(encryptedArray.length - 16);
  
    return {
      iv: Array.from(iv), // IV in array form
      encryptedData: Array.from(ciphertext), // Ciphertext in array form
      authTag: Array.from(authTag), // AuthTag in array form (last 16 bytes)
    };
  }
  

  async function decrypt(encryptedMessage) {
    let encryptedMessageJSON;
    try {
        encryptedMessageJSON = JSON.parse(encryptedMessage);
    } catch(err) {
        encryptedMessageJSON = encryptedMessage;
    }
    // console.log(encryptedMessageJSON);
    // Check if iv, encryptedData, and authTag are present
    if (!encryptedMessageJSON.iv || !encryptedMessageJSON.encryptedData || !encryptedMessageJSON.authTag) {
        throw new Error("Decryption failed: missing parameters");
    }
  
    // Convert hex strings to Uint8Array using the helper function
    const iv = hexToUint8Array(encryptedMessageJSON.iv);  // Convert IV from hex to Uint8Array
    const encryptedText = hexToUint8Array(encryptedMessageJSON.encryptedData);  // Convert encrypted text from hex to Uint8Array
    const authTag = hexToUint8Array(encryptedMessageJSON.authTag);  // Convert authTag from hex to Uint8Array
  
    // console.log(iv, encryptedText, authTag);
    // Combine the encrypted text and authTag (Web Crypto API doesn't expose authTag directly)
    const combinedText = new Uint8Array([...encryptedText, ...authTag]);
  
    const key = await generateKey();  // Get the AES-GCM key
  
    // Perform decryption using AES-GCM
    const decryptedBuffer = await crypto.subtle.decrypt(
      {
        name: 'AES-GCM',
        iv: iv,  // The IV used during encryption
      },
      key,
      combinedText  // Combined encrypted text and authTag
    );
  
    return new TextDecoder().decode(decryptedBuffer);  // Decode the decrypted buffer to a string
  }
  

  function Onboarding() {
    const [currentSession, setCurrentSession] = useState(null); // Active session
    const [messages, setMessages] = useState([]); // Messages in current session
    const [input, setInput] = useState(''); // User input
    const [loading, setLoading] = useState(false); // Loading state
    const [onboardingCompleted, setOnboardingCompleted] = useState(false); // Onboarding state
    const [isOnboarding, setIsOnboarding] = useState(true);

    const navigate = useNavigate();

    // Function to initiate onboarding chat
    const startOnboardingChat = useCallback(async () => {
      const token = localStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_API_URL}/chat/onboarding/start`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
          }
      });
      
      const messagesData = await response.json();
    //   console.log(messagesData);
    //   console.log(messagesData.messages);
      const decryptedMessages = await Promise.all(
          messagesData.messages.map(async (message) => {
              const decryptedUserMessage = await decrypt(message.user.text);
              const decryptedBotMessage = await decrypt(message.bot.text);
              const decryptedSentiment = await decrypt(message.sentiment.text);
              return { user: decryptedUserMessage, bot: decryptedBotMessage, sentiment: decryptedSentiment, timestamp: message.timestamp };
          })
      );
      setMessages(decryptedMessages);
      setInput('');
      setIsOnboarding(false);
      setCurrentSession(messagesData._id);
      setLoading(false);
  }, []);

  // Fetch regular messages for the selected session
  useEffect(() => {
    if (isOnboarding && !onboardingCompleted) {
        // console.log('Triggered');
        startOnboardingChat();  // Trigger onboarding chat if this is the first time
        // console.log('Started');
        // console.log(currentSession);
      }
  }, [currentSession, isOnboarding, onboardingCompleted, startOnboardingChat]);

    // Send a message in the current session
    const sendMessage = async () => {
        if (!currentSession) return; // Ensure a session is selected
        setLoading(true); // Set loading to true when processing starts

        const token = localStorage.getItem('token');
        const encryptedMessage = await encrypt(input);

        const response = await fetch(`${process.env.REACT_APP_API_URL}/chat/onboarding/${currentSession}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ message: encryptedMessage }),
        });

        const data = await response.json();
        // console.log(data);
        const decryptedBotMessage = await decrypt(data.response);
        const decryptedSentiment = await decrypt(data.sentiment);
        setMessages([...messages, { user: input, bot: decryptedBotMessage, sentiment: decryptedSentiment }]);
        setInput('');

        // If onboarding is complete, redirect to regular chat
        if (data.onboardingComplete) {
            setOnboardingCompleted(true);
          navigate('/chat');  // Redirect to the regular chat route
        }
        setLoading(false); // Set loading to false when processing is done
    };

    return (
        <div className="chat-app-container">

            {/* Chat interface */}
            <div className="chat-container">
                <p color='Red'>This is the development version. Please do not enter confidential Information!</p>
                <h2>Onboarding Chat</h2>
                <div className="chat-messages">
                    {messages.map((msg, index) => (
                        <div key={index}>
                            <p><strong>You:</strong> {msg.user}</p>
                            <div className="assistant-message">
                                <ReactMarkdown className="assistant-content">
                                    {typeof msg.bot === 'string' ? msg.bot : JSON.stringify(msg.bot)}
                                </ReactMarkdown>
                            </div>
                        </div>
                    ))}
                </div>

                {currentSession && (
                    <div className="chat-input">
                        <input
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            placeholder="Type your message"
                        />
                        <button onClick={sendMessage} disabled={loading}>
                            {loading ? 'Processing...' : 'Send'}
                        </button>
                    </div>
                )}
                <div>
                    You can come back to onboarding when you want to provide more information about yourself.
                    The information provided in chats will not be shared accrosss chats.
                </div>
            </div>
        </div>
    );
}

export default Onboarding;