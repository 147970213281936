import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import { FaComments, FaUserCircle, FaSignOutAlt, FaSignInAlt, FaHome, FaRegistered } from 'react-icons/fa'; // Icons for navigation
import '../App.css'; // Ensure styling is updated accordingly

const Layout = () => {
  let token = localStorage.getItem('token');
  let userId = localStorage.getItem('userId');

  return (
    <div className="layout-container">
      {/* Sidebar Navigation */}
      <aside className="sidebar">
        <div className="sidebar-logo">
          <img src="/logo.png" alt="ManoStithi" className="logo"/>
        </div>
        <nav className="sidebar-nav">
          <ul>
            {/* Use icons for navigation items */}
            {token && <li><Link to="/"><FaHome className="nav-icon" /></Link></li>}
            {token && userId && <li><Link to="/chat"><FaComments className="nav-icon" /></Link></li>}
            {/* {token && userId && <li><Link to="/emotions"><FaSmile className="nav-icon" /></Link></li>} */}
            {token && userId && <li><Link to="/onboarding"><FaUserCircle className="nav-icon" /></Link></li>}
            {!token && <li><Link to="/login"><FaSignInAlt className="nav-icon" /></Link></li>}
            {!token && <li><Link to="/register"><FaRegistered className="nav-icon" /></Link></li>}
            {token && <li><Link to="/logout"><FaSignOutAlt className="nav-icon" /></Link></li>}
          </ul>
        </nav>
      </aside>

      {/* Main content area, ensuring chat takes the full right side */}
      <main className="main-content">
        <Outlet /> {/* Nested routes will render here */}
      </main>
    </div>
  );
};

export default Layout;
