import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/HomePage.css'; // Custom styling for home page

function HomePage() {
    return (
        <div className="home-container">
            <header className="home-header">
                <h1>Welcome to ManoStithi!</h1>
                <p>We're here to listen and support you in your mental health journey.</p>
                <p color='Red'>This is the development version. Please do not enter confidential Information!</p>
            </header>

            <section className="home-section">
                <h2>Our Chat Experience</h2>
                <p>
                    Our virtual assistant is designed to help you explore your thoughts and emotions in a safe,
                    supportive environment. Whether you're feeling anxious, stressed, or just need someone to talk to,
                    our assistant can guide you through various mental health challenges.
                </p>
                <p>
                    With the power of GPT-4o-mini, our assistant can offer suggestions for self-care, reflection, 
                    or resilience building based on your emotional state. The assistant provides empathetic support and 
                    helps you reflect on your thoughts and feelings.
                </p>
                <Link to="/chat" className="cta-button">Start Chat</Link>
            </section>

            <section className="home-section">
                <h2>Personalized Onboarding</h2>
                <p>
                    Before starting your chat, we guide you through a short onboarding conversation to better understand your current mood,
                    emotional challenges, and goals. This helps tailor your experience to fit your needs without collecting any personally identifiable information (PII).
                </p>
                <p>
                    The onboarding process allows us to understand what type of support you're looking for—whether it's relaxation techniques, 
                    problem-solving, or simply emotional validation. The information helps us personalize your experience without invading your privacy.
                </p>
                <Link to="/onboarding" className="cta-button">Begin Onboarding</Link>
            </section>

            <section className="home-section">
                <h2>What You Can Expect</h2>
                <ul>
                    <li>Non-judgmental, compassionate conversation driven by advanced AI.</li>
                    <li>Guidance through mental health challenges like stress, anxiety, and low mood.</li>
                    <li>Help with reflection, relaxation techniques, and goal-setting.</li>
                    <li>Anonymity: We never collect personal information like your name or email.</li>
                </ul>
            </section>

            <section className="home-section caution-section">
                <h2>What This Platform Should Not Be Used For</h2>
                <p>
                    While we aim to offer meaningful support, this platform is not intended to replace professional mental health care.
                    If you're experiencing severe emotional distress or suicidal thoughts, we strongly urge you to seek help from a licensed therapist or mental health professional.
                </p>
                <p>
                    In case of emergency, please contact a mental health crisis hotline or your nearest emergency service.
                </p>
            </section>

            <footer className="home-footer">
                <p>© {new Date().getFullYear()} ManoSutra. All rights reserved.</p>
            </footer>
        </div>
    );
}

export default HomePage;
