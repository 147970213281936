import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function PostAuthHandler() {
  const navigate = useNavigate();

  useEffect(() => {
    // Capture token and userId from the server response
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    const userId = params.get('userId');

    if (token && userId) {
      // Store token and userId in localStorage
      localStorage.setItem('token', token);
      localStorage.setItem('userId', userId);

      // Determine where to navigate based on the stored redirect path
      const postAuthRedirect = sessionStorage.getItem('postAuthRedirect') || '/';
      sessionStorage.removeItem('postAuthRedirect'); // Clear the stored redirect path

      // Navigate to the desired path
      navigate(postAuthRedirect);
    }
  }, [navigate]);

  return (
    <div>
      <h2>Authenticating...</h2>
      {/* Optionally, add a spinner or a loading state */}
    </div>
  );
}

export default PostAuthHandler;
