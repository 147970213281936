import React from 'react';
import { useNavigate } from 'react-router-dom';

function Logout() {
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('email');
    navigate('/');
    window.location.reload();
  };

  return (
    <div style={{width: '60vw', padding:'auto', margin: '150px',}}>
      <h2>Logout</h2>
      <p>Please confirm that you want to logout from the application.</p>
      <form onSubmit={handleSubmit}>
        <button type="submit" style={{'background-color': '#4CAF50'}}>Logout</button>
      </form>
    </div>
  );
}

export default Logout;
